import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["partialToUpdate"];

  updateHtml(event) {
    const [_data, _status, xhr] = event.detail;
    this.partialToUpdateTarget.innerHTML = xhr.response;

    this.renderRecaptcha();
  }

  handleSuccess() {
    window.location.reload();
  }

  renderRecaptcha() {
    const recaptchaElement = document.querySelector(".g-recaptcha");
    const siteKey = recaptchaElement.getAttribute("data-sitekey");

    if (window.grecaptcha && siteKey) {
      window.grecaptcha.render(recaptchaElement, {
        sitekey: siteKey,
      });
    } else {
      console.error(
        "reCAPTCHA is not properly initialized or site key is missing."
      );
    }
  }
}
